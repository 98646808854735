<template>
  <VideosYoutubeEmbed
    v-if="video.type === 'YouTube'"
    :id="id"
    :style="maxWidth + width"
    class="video"
    :title="video.altText ? video.altText : 'youtube video'"
    :class="styleClasses"
    :video="video"
    :youtube-player-state="ytPlayerState"
  >
    <div
      v-if="video.coverImage !== undefined && !hideCoverImg"
      class="image--cover-image absolute h-full w-full"
    >
      <ImagesNuxtImg
        v-if="video.coverImage.fields.file"
        :url="video.coverImage.fields.file.url"
        class="right-0 top-0 z-20 h-full w-full object-cover md:block"
        :alt="'cover image'"
      />
      <div
        class="image--cover-image__text absolute bottom-0 left-0 z-30 h-full w-full cursor-pointer"
        tabindex="0"
        @click="playYtVideo()"
        @keydown="playYtVideo()"
      >
        <div
          v-if="video.coverImageText"
          :class="labelPosition"
          class="absolute"
        >
          <p class="mb-0 inline text-xl font-medium text-white">
            {{ video.coverImageText }}
          </p>
          <IconsSvgPlayIconBackground class="svg--play-icon" />
        </div>
      </div>
    </div>
    <div
      v-else-if="video.coverImage === undefined && !hideCoverImg"
      class="image--cover-image absolute h-full w-full"
    >
      <img
        :src="`https://i3.ytimg.com/vi/${video.id}/maxresdefault.jpg`"
        class="right-0 top-0 z-[3] block h-full object-cover"
        :alt="'decorative pattern'"
      />
      <div
        class="image--cover-image__text absolute bottom-0 left-0 z-10 h-full w-full cursor-pointer"
        tabindex="0"
        @click="playYtVideo()"
        @keydown="playYtVideo()"
      >
        <div class="absolute" :class="labelPosition">
          <p class="mb-0 inline text-xl font-medium text-white">
            {{
              video.coverImageText !== undefined
                ? video.coverImageText
                : 'Watch Video'
            }}
          </p>
          <IconsSvgPlayIconBackground class="svg--play-icon" />
        </div>
      </div>
    </div>
  </VideosYoutubeEmbed>
  <VideosAssetVideo
    v-else-if="
      video.type === 'Asset' &&
      video.video?.fields?.file &&
      !video.videoControls
    "
    :id="id"
    :style="maxWidth + width"
    class="video"
    :class="styleClasses"
    :video="video"
    :state="mp4PlayerState"
    @video-loaded="$emit('video-loaded')"
  >
  </VideosAssetVideo>
  <VideosAssetVideo
    v-else-if="video.type === 'Asset' && video.video?.fields?.file"
    :id="id"
    :style="maxWidth + width"
    class="video video--with-controls"
    :class="styleClasses"
    :video="video"
    :state="mp4PlayerStateWithControls"
    @video-loaded="$emit('video-loaded')"
    @video-has-controls="$emit('video-has-controls', video.videoControls)"
  >
    <template v-if="video.videoControls">
      <button
        v-if="video.coverImageText"
        :class="{
          'bottom-3 right-3': labelPosition === 'bottom-3 left-3',
          'bottom-3 left-3': labelPosition === 'bottom-3 right-3',
        }"
        class="video--controls-button button absolute z-30 h-[25px] w-[25px] border-0 bg-transparent outline-none hover:cursor-pointer"
        aria-live="assertive"
        :aria-label="mp4PlayerStateWithControls"
        :aria-pressed="isMp4Playing ? true : false"
        @click="toggleMp4State()"
      >
        <IconsSvgPauseIcon
          v-show="isMp4Playing"
          :fill="'white'"
          class="h-full w-full"
        />
        <IconsSvgPlayIcon
          v-show="!isMp4Playing"
          :fill="'white'"
          class="h-full w-full"
        />
      </button>
      <button
        v-if="!video.coverImageText"
        :class="labelPosition"
        class="video--controls-button button absolute z-30 h-[25px] w-[25px] border-0 bg-transparent outline-none hover:cursor-pointer"
        aria-live="assertive"
        :aria-label="mp4PlayerStateWithControls"
        :aria-pressed="isMp4Playing ? true : false"
        @click="toggleMp4State()"
      >
        <IconsSvgPauseIcon
          v-show="isMp4Playing"
          :fill="'white'"
          class="h-full w-full"
        />
        <IconsSvgPlayIcon
          v-show="!isMp4Playing"
          :fill="'white'"
          class="h-full w-full"
        />
      </button>
      <button
        v-if="video.coverImageText"
        :class="labelPosition"
        class="button absolute z-10 h-auto w-fit border-0 bg-transparent outline-none hover:cursor-pointer"
        @click="
          $emitter.emit('playYouTube', {
            url: video.id ? video.id : '',
            openModal: true,
          })
        "
        @keydown.enter="
          $emitter.emit('playYouTube', {
            url: video.id ? video.id : '',
            openModal: true,
          })
        "
      >
        <p
          class="mb-0 inline text-xl font-medium text-white hover:cursor-pointer"
        >
          {{ video.coverImageText }}
        </p>
        <IconsSvgPlayIconBackground
          class="svg--play-icon pointer-events-none"
        />
      </button>
    </template>
  </VideosAssetVideo>
  <div
    v-else-if="
      video.type === 'Mobile Device' || video.type === 'Desktop Device'
    "
    :class="deviceClasses + styleClasses"
  >
    <video
      v-if="video.video?.fields?.file"
      v-lazy-load
      playsinline
      autoplay
      muted
      preload="auto"
      loop
    >
      <source :src="video.video.fields.file.url" type="video/mp4" />
    </video>
    <p v-if="video.style === 'Blog' && video.footnote" class="mt-1 text-xs">
      {{ video.footnote }}
    </p>
    <LinksContentfulLink v-if="id" :id="id" label="video" />
  </div>
  <div
    v-else-if="video.type === 'Vimeo'"
    ref="vimeo"
    :style="maxWidth"
    class="video"
  >
    <div class="video--frameless relative">
      <iframe
        id="vimeo-video"
        v-lazy-load
        class="absolute block h-full w-full"
        :src="`https://player.vimeo.com/video/${video.id}?autoplay=1&loop=1&muted=1&portrait=0&controls=${videoControls}&title=0`"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
        :title="video.altText ? video.altText : ''"
      ></iframe>
    </div>
    <LinksContentfulLink v-if="id" :id="id" label="video" />
  </div>
</template>

<script>
  export default {
    props: {
      video: {
        type: Object,
        required: true,
        default: () => ({}),
      },
      lazy: {
        type: Boolean,
        required: false,
        default: true,
      },
      controls: {
        type: Boolean,
        required: false,
        default: false,
      },
      youtubePlayerState: {
        type: String,
        required: false,
        default: 'unstarted',
      },
      mp4PlayerState: {
        type: String,
        default: 'play',
      },
      id: {
        type: String,
        required: false,
        default: null,
      },
    },
    emits: ['video-loaded', 'video-has-controls'],
    data() {
      return {
        videoControls: '0',
        hideCover: false,
        youtubeState: 'unstarted',
        isMp4Playing: false,
      };
    },
    computed: {
      labelPosition() {
        const labelPosition = {
          'Bottom Left': 'bottom-3 left-3',
          'Bottom Right': 'bottom-3 right-3',
        };
        return this.video.labelPosition
          ? labelPosition[this.video.labelPosition]
          : labelPosition['Bottom Left'];
      },
      mp4PlayerStateWithControls() {
        return this.isMp4Playing ? 'play' : 'pause';
      },
      ytPlayerState() {
        return this.youtubeState;
      },
      hideCoverImg() {
        return this.hideCover;
      },
      deviceClasses() {
        const deviceClasses =
          this.video.type === 'Mobile Device'
            ? 'device--mobile'
            : this.video.type === 'Desktop Device'
              ? 'device--laptop'
              : '';
        return `device ${deviceClasses}`;
      },
      maxWidth() {
        return this.video.maxWidth ? `max-width:${this.video.maxWidth}px;` : '';
      },
      width() {
        return this.video.width ? `width:${this.video.width}px;'` : '';
      },
      styleClasses() {
        const classes = {
          'Right Aligned': ' float-right',
          Blog: ' video--blog',
        };
        return this.video.style ? classes[this.video.style] : '';
      },
    },
    watch: {
      youtubePlayerState() {
        if (this.youtubePlayerState === 'play') {
          this.playYtVideo();
        }
      },
    },
    mounted() {
      const isVimeo = this.$refs.vimeo ? this.$refs.vimeo : null;
      if (this.video.autoplay) {
        this.isMp4Playing = true;
      }
      if (isVimeo) {
        const matchMedia = window.matchMedia(' (max-width: 736px) ');
        const video = document.querySelector('#vimeo-video');
        const videoSrc = video ? video.getAttribute('data-id') : '';

        const vm = this;

        function swapVideo(matchMedia) {
          vm.videoControls = matchMedia.matches ? '1' : '0';
        }
        swapVideo(matchMedia);
        matchMedia.addListener(swapVideo);
      }
    },
    methods: {
      toggleMp4State() {
        this.isMp4Playing = !this.isMp4Playing;
      },
      playYtVideo() {
        this.youtubeState = 'play';
        this.hideCover = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .video--frameless {
    padding-bottom: 44.6%;
    box-shadow: 1px 0 41px 2px rgba(0, 38, 62, 0.06);
  }
  .image--cover-image,
  .video--with-controls :deep(.video__container) {
    &::after {
      content: '';
      @apply absolute bottom-0 left-0 w-full;

      height: 66%;
      background: linear-gradient(0deg, #000 -20.25%, rgba(0, 0, 0, 0) 87%);
    }
  }
</style>
