<template>
  <LinksContentfulLink v-if="id" :id="id" label="button" />
  <a
    v-if="button.action && button.action === 'Modal Popup'"
    :id="button.trackingId ? button.trackingId : id"
    :data-id="button.linkUrl"
    class="btn--modal"
    :class="buttonClasses"
    role="button"
    @click.prevent="
      openModal(button.linkUrl ? button.linkUrl : '');
      trackLink();
    "
    >{{ button.text ? button.text : 'Modal Popup' }}</a
  >
  <ButtonsSemanticButton
    v-else-if="
      button.action && button.action === 'Url Filter' && button.type === 'Link'
    "
    :id="button.trackingId ? button.trackingId : id"
    class="flex items-center"
    :classes="semanticButtonClasses"
    :text="button.text ? button.text : ''"
    @click="
      updateUrlFilterParams(
        button.uniqueId ? button.uniqueId : '',
        button.selectOptions ? button.selectOptions : ['']
      );
      trackLink();
    "
  >
    <IconsSvgArrowDownIcon v-if="button.icon === 'Arrow Down'" class="ml-1/2" />
  </ButtonsSemanticButton>
  <ButtonsSemanticButton
    v-else-if="button.type === 'Select'"
    :id="button.trackingId ? button.trackingId : id"
    :type="button.type"
    :select-options="
      button.selectOptions !== undefined ? button.selectOptions : []
    "
    class="text-[18px] capitalize"
    :classes="semanticButtonClasses"
    :text="''"
    @click="
      updateUrlFilterParams(
        button.uniqueId ? button.uniqueId : '',
        button.linkUrl ? button.linkUrl : ''
      );
      trackLink();
    "
  >
  </ButtonsSemanticButton>
  <LinksLocaleLink
    v-else-if="button.style === 'App Store' || button.style === 'Google Play'"
    :link="button.linkUrl"
  >
    <ImagesNuxtImg
      v-if="button.style === 'App Store'"
      :classes="'button--app inline'"
      :url="'https://images.ctfassets.net/bx9krvy0u3sx/50MyBJwhdNzow664gbDwJU/565fdb71adf0792818b3552b877ebd3f/apple.jpg'"
      :alt="'app store button'"
    />
    <ImagesNuxtImg
      v-else-if="button.style === 'Google Play'"
      :classes="'button--app inline'"
      :url="'https://images.ctfassets.net/bx9krvy0u3sx/5zFPvFkg8o4Khh7tbfOCWe/65c273838647096655763e996bbe1c48/google.jpg'"
      :alt="'google play button'"
    />
  </LinksLocaleLink>
  <LinksLocaleLink
    v-else-if="button.linkUrl"
    :id="button.trackingId ? button.trackingId : id"
    :link="button.linkUrl"
    :target="button.target ? button.target : '_self'"
    :class="buttonClasses"
    @click="trackLink()"
    >{{ button.text
    }}<IconsSvgArrowDownIcon
      v-if="button.icon === Icon.ARROW_DOWN"
      :stroke-color="strokeColor"
      class="ml-1/2 inline-block"
    />
    <IconsSvgPhone
      v-if="button.icon === Icon.PHONE"
      :stroke-color="strokeColor"
      class="mr-1/2 inline-block"
    />
  </LinksLocaleLink>
</template>

<script>
  import { Icon, Colors } from '~/types/enums/button';
  import { fadeIn } from '~/utils/html-helpers';

  export default {
    props: {
      button: {
        type: Object,
        required: true,
      },
      id: {
        type: String,
        required: false,
        default: null,
      },
    },
    setup() {
      const { analyticsTrack } = useAnalytics();

      return {
        Icon,
        Colors,
        analyticsTrack,
      };
    },
    data() {
      return {
        buttonClasses: '',
      };
    },
    computed: {
      strokeColor() {
        let color = '#fff';
        if (
          this.button.style === Colors.BLUE_PRIMARY ||
          this.button.style === Colors.WHITE_SECONDARY ||
          this.button.style === Colors.RED_PRIMARY ||
          this.button.style === Colors.RED_SECONDARY_SQUARE
        ) {
          return color;
        }
        if (
          this.button.style === Colors.WHITE_PRIMARY ||
          this.button.style === Colors.BLUE_SECONDARY_SQUARE ||
          this.button.style === Colors.BLUE_PRIMARY_OUTLINE
        ) {
          color = '#0384FB';
        }
        return color;
      },
      semanticButtonClasses() {
        const buttonColors = {
          'Red Primary': 'btn btn--red btn--red:hover',
          'Red Secondary': 'btn btn--red-secondary btn--red:hover',
          'White Secondary': 'btn btn--white-secondary',
          'White Primary': 'btn btn--white-primary',
          'Green Secondary Open': 'btn btn--green-secondary btn--green:hover',
          'Blue Primary': 'btn btn--blue btn--blue:hover',
          'Blue Primary Full Width': 'btn btn--blue btn--blue:hover w-full',
          'Blue Secondary Square': 'btn btn--blue-secondary btn--blue:hover',
        };

        const linkStyles = {
          'Blue Primary': 'text-blue font-bold hover:text-dark',
        };

        const defaultClasses =
          this.button.type === 'Default' ? buttonColors[this.button.style] : '';

        const linkClasses =
          this.button.type === 'Link' ? linkStyles[this.button.style] : '';

        return defaultClasses + linkClasses;
      },
    },
    created() {
      const styles = {
        'Red Primary': 'btn btn--red btn--red:hover',
        'Red Secondary': 'btn btn--red-secondary btn--red:hover',
        'Teal Primary': 'btn btn--teal btn--teal:hover',
        'Teal Secondary': 'btn btn--teal-secondary btn--teal:hover',
        'White Secondary': 'btn btn--white-secondary',
        'White Primary': 'btn btn--white-primary',
        'Green Secondary Open': 'btn btn--green-secondary btn--green:hover',
        'Blue Primary': 'btn btn--blue btn--blue:hover',
        'Blue Primary Full Width': 'btn btn--blue btn--blue:hover w-full',
        'Blue Secondary Square': 'btn btn--blue-secondary btn--blue:hover',
      };
      const icons = {
        'Link Out': 'btn--icon btn--icon-link',
        Download: 'btn--icon btn--icon-download',
        Play: 'btn--icon btn--icon-video',
        Modal: 'btn--icon btn--icon-modal',
        'Arrow Down': 'btn--icon btn--icon-arrow-down',
        Phone: 'btn--icon btn--icon-phone flex flex-row-reverse',
      };
      this.buttonClasses =
        this.button.style && this.button.icon
          ? `mb-1 md:mr-1/2 ${styles[this.button.style]} ${
              icons[this.button.icon] + ' pointer-events-auto'
            }`
          : this.button.style
            ? styles[this.button.style] + ' pointer-events-auto'
            : '';
    },

    methods: {
      trackLink() {
        const id = this.button.trackingId ? this.button.trackingId : this.id;
        this.analyticsTrack('Button Clicked', {
          link: this.button.linkUrl,
          tracking_id: id,
          text: this.button.text ? this.button.text : '',
        });
      },
      scrollTo(id) {
        if (document.getElementById(id)) {
          const element = document.getElementById(id);
          window.scrollTo({
            top: element.getBoundingClientRect().top,
            behavior: 'smooth',
          });
        }
      },
      updateUrlFilterParams(id, departments) {
        this.scrollTo(id);
        this.$emitter.emit('updateParams', departments);
      },
      openModal(value) {
        if (value.includes('youtube')) {
          this.$emitter.emit('playYouTube', {
            url: value,
            openModal: true,
          });
        } else {
          const modal = document.querySelector(`#${value}`);
          fadeIn(modal, 'block');
          document.querySelector('html').classList.add('overflow-y-hidden');
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .button--app {
    max-height: 40px;

    &:first-of-type {
      padding-right: 12px;
    }
  }
</style>
