export const localeMappings = {
  fr: 'fr',
  uk: 'en-GB',
  de: 'de',
  mx: 'es-MX',
  ca: 'en-CA',
  'fr-ca': 'fr-CA',
  us: 'en-US',
  'en-US': 'en-US',
  nl: 'nl',
};

export const gqlLocaleMappings = {
  fr: 'fr',
  engb: 'uk',
  de: 'de',
  esmx: 'mx',
  enca: 'ca',
  frca: 'fr-ca',
  enus: '',
  nl: 'nl',
};

export const LOCALE_CODE_TO_ISO = {
  ca: 'en-CA',
  de: 'de',
  'en-US': 'en-US',
  fr: 'fr',
  'fr-ca': 'fr-CA',
  mx: 'es-MX',
  nl: 'nl',
  uk: 'en-GB',
  us: 'en-US',
};

export const supportedLocales = [
  'fr',
  'en-GB',
  'de',
  'es-MX',
  'en-CA',
  'fr-CA',
  'en-US',
  'nl',
];

export const supportedEULocales = ['fr', 'de', 'uk', 'nl'];

export const hrefLocales = ['fr', 'uk', 'de', 'mx', 'ca', 'fr-ca', 'nl'];

export const fallbackLocales = ['en-CA', 'fr-CA'];

export const fallbackMappings = {
  'fr-ca': 'fr',
  ca: 'en-US',
  'en-CA': 'en-US',
  'fr-CA': 'fr',
};

export const ctflMappings = {
  fr: 'fr',
  'en-GB': 'uk',
  de: 'de',
  'es-MX': 'mx',
  'en-CA': 'ca',
  'fr-CA': 'fr-ca',
  'en-US': 'en-US',
  nl: 'nl',
};

export const flagMappings = {
  fr: 'FR',
  uk: 'UK',
  de: 'DE',
  mx: 'MX',
  ca: 'CA',
  'fr-ca': 'FR-CA',
  'en-US': 'US',
  us: 'US',
  nl: 'NL',
};

export const IPQS_LOCALES = {
  'en-US': 'US',
  us: 'US',
  ca: 'CA',
  'fr-ca': 'CA',
  fr: 'FR',
  uk: 'GB',
  de: 'DE',
  mx: 'MX',
  nl: 'NL',
};

export const LOCALE_CODE_TO_COUNTRY = {
  ca: 'Canada',
  de: 'Germany',
  'en-us': 'United States',
  fr: 'France',
  'fr-ca': 'Canada',
  mx: 'Mexico',
  nl: 'Netherlands',
  uk: 'United Kingdom',
};

export const LOCALE_CODE_TO_WRITTEN_LANGUAGE = {
  ca: 'English',
  de: 'German',
  'en-us': 'English',
  fr: 'French',
  'fr-ca': 'French',
  mx: 'Spanish',
  nl: 'Dutch',
  uk: 'English',
};
