<template>
  <ClientOnly>
    <NuxtLink
      v-show="!isProduction && props.id && getShowEntries"
      class="ctfl-button pointer-events-auto absolute z-[9998] transition-all hover:scale-105"
      :to="contentfulEntryUrl"
      external
      target="_blank"
      >View {{ label ? label : 'on Contentful' }}</NuxtLink
    >
  </ClientOnly>
</template>

<script setup>
  const widgetStore = useDevModeWidgetStore();
  const { getShowEntries } = storeToRefs(widgetStore);

  const props = defineProps({
    id: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
  });

  const { isProduction, ctflProdSpaceId } = useRuntimeConfig().public;
  const contentfulEntryUrl = `https://app.contentful.com/spaces/${ctflProdSpaceId}/entries/${props.id}`;
</script>

<style lang="scss" scoped>
  .ctfl-button {
    @apply rounded-full bg-red px-1/2 py-1/4 text-xxs font-bold uppercase text-white;
  }
</style>
